<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">


      <a-form-model-item label="轮播图" prop="bannerUsl">
        <a-upload
            listType="picture-card"
            class="avatar-uploader"
            v-model="form.bannerUsl"
            :showUploadList="false"
            :customRequest="handleChange"
        >
          <!--
                      @change="handleChange"-->
          <img v-if="form.bannerUsl" :src="form.bannerUsl" alt="logo"
               style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>

      <a-form-model-item label="视频" prop="videoUrl">
        <a-upload
            listType="picture-card"
            accept=".mp4"
            class="avatar-uploader"
            v-model="form.videoUrl"
            :showUploadList="false"
            :customRequest="handleVideo"
        >
          <!--
                      @change="handleChange"-->
          <div v-if="form.videoUrl">
            <video id="myVideo" :src="form.videoUrl"  width="300" height="150" controls></video>

          </div>

          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>

          </div>
        </a-upload>
<!--        <div v-if="this.process">-->
        <a-progress v-if="uploadLoad" style="margin-top: 10px;margin-bottom: 10px;width: 20%"  :percent=this.uploadSize />
<!--        </div>-->


      </a-form-model-item>



      <a-form-model-item label="排序" prop="sort">
        <a-input v-model="form.sort" placeholder="请输入排序"/>
      </a-form-model-item>


      <a-form-model-item  label="跳转类型" prop="type">
        <a-select placeholder="请选择跳转类型" @change="updateType" v-model="form.type">
          <a-select-option :key="0">短剧</a-select-option>
          <a-select-option :key="1">外部链接</a-select-option>
        </a-select>
      </a-form-model-item>



      <a-form-model-item  v-if="this.type==0" label="是否跳转" prop="isJump">
        <a-select placeholder="请选择是否跳转" @change="updateIsGo" v-model="form.isJump">
          <a-select-option :key="false">否</a-select-option>
          <a-select-option :key="true">是</a-select-option>
        </a-select>
      </a-form-model-item>





      <a-form-model-item v-if="this.type==1" label="外部链接" prop="linkUrl">
          <a-input  v-model="form.linkUrl" placeholder="请输入外部链接" />
      </a-form-model-item>


      <a-form-model-item v-if="this.isJup && this.type==0" label="短剧" prop="videoId">
        <a-select
            v-model="form.videoId"
            showSearch
            style="width: 100%"
            placeholder="请选择短剧"
            allow-clear
            optionFilterProp="children"
        >
          <a-select-option :value="item.id" :key="item.id" :title="item.videoName" v-for="(item,index) in listAll">
            {{ item.videoName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <!--      <a-form-model-item label="备注" prop="remark" >-->
      <!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
      <!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getBanner, addBanner, updateBanner} from '@/api/banner/banner'
import {listVideo} from "@/api/video/video";
import UploadFileToVOD from "@/api/vod.js"

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      uploadLoading: false,
      uploadLoad: false,
      process: false,
      formTitle: '',
      uploadSize:0,
      fileList: [],
      type: 0,
      listAll: [],
      isJup: false,
      // 表单参数
      form: {
        id: null,

        sort: null,

        videoId: null,

        isJump: false,
        linkUrl: null,
        videoUrl: null,
        type: null,

        bannerUsl: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        sort: [
          {required: true, message: '排序不能为空'}
        ],
        isJump: [
          {required: true, message: '是否跳转不能为空'}
        ],
        linkUrl: [
          {required: true, message: '外部链接不能为空'}
        ],
        videoId: [
          {required: true, message: '请选择短剧'}
        ],
        bannerUsl: [
          {required: true, message: '轮播图url不能为空'}
        ]
      }
    }
  },
  filters: {},
  created() {
    this.getGather();
    this.uploadSize=0

  },
  computed: {},
  watch: {},
  mounted() {
  },

  methods: {
    handleChange(info) {
      let that = this
      new UploadFileToVOD([info.file], {
        success: function (fileName) {
          debugger
          console.log(fileName)
          that.form.bannerUsl = fileName[0];//文件名
          console.log(11111111111111111)
          console.log(this.form.bannerUsl)
          that.uploadLoading = false;
        }
      });
      that.uploadLoading = false;
    },

    handleVideo(info) {
      var videos = document.getElementById("myVideo");
      this.uploadLoad=true
      if (videos) {
        videos.pause()
      }

      if (info.file.status === 'uploading') {
        this.uploadLoading = true;
        return;
      }
      let that = this

      that.form.videoUrl = ""
      new UploadFileToVOD([info.file], {
        success: function (fileName) {

          that.form.videoUrl = fileName[0];//文件名
          that.uploadLoading = false;
        },
        vProgress:function (val) {
          that.uploadSize=val * 100;
        }
      });
      that.uploadLoading = false;
    },



    updateType(value) {
      this.type=value
    },
    beforeUpload: function (file) {
      var fileType = file.type;
      if (fileType.indexOf('image') < 0) {
        this.$message.warning('请上传图片');
        this.uploadLoading = false
        return false;
      }
      //TODO 驗證文件大小
    },
    updateIsGo(value) {
      this.isJup = value
    },
    onClose() {
      this.isJup = false
      this.uploadSize=0
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    getGather() {
      listVideo({isRelease: 1}).then(res => {
        this.listAll = res.data;
      })
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        sort: null,
        videoId: null,
        isJump: null,
        bannerUsl: null,
        createTime: null,
        remark: null,
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.uploadLoad=false
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      this.uploadLoad=false
      const id = row ? row.id : ids
      getBanner({"id": id}).then(response => {
        this.form = response.data
        this.isJup = this.form.isJump
        this.type=this.form.type
        this.open = true
        this.formTitle = '修改'
      })

    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateBanner(this.form).then(response => {
              this.$message.success(
                  '修改成功',
                  3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addBanner(this.form).then(response => {
              this.$message.success(
                  '新增成功',
                  3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
